$base-color: #000;
$brand-color: rgb(220,45,39);
$border-dark: rgba($base-color, 0.88);


main {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-top: 80px;
}


.shownav {
  animation: fadeInDown 1s ease-in-out;
}

.App-header {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  box-shadow: none !important;
  top: 0;
  transition: opacity .4s ease,transform .4s ease;
  visibility: visible !important;
  will-change: transform;
  z-index: 2006;
  animation: fadeInDown 1s ease-in-out;
}fbu

.App-header .hide {
  opacity: 0;
  visibility: hidden !important;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

header > div {
  display: flex !important;
  Justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

header.bottomShadow::before {
  opacity: 1;
}

header::before {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: rgba(0,0,0,.15);
  //opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
}

.App-logo {
  margin: 14px;
  width: 80px;
}

.btn-nav {
  background: $brand-color;

  height: 45px;
  font-size: 16px;
  border-radius: 30px;
  color: rgb(255, 255, 255);
  line-height: normal;
  border: medium none;
  align-items: center;
  padding: 12px 32px !important;
  margin: 12px 24px;
  box-shadow: 0 2px 0 rgba(0,0,0,.015);
  cursor: pointer;
  font-weight: 400;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  user-select: none;
  white-space: nowrap;
}

.btn-nav:hover {
  background: rgb(0, 0, 0);
  //color: rgb(0, 0, 0);
}

.form {
  display: inline-block;
  position: relative;
  border-bottom: 1px solid black;
  width: 100%;
  max-width: 400px;
}

.formfield {
  touch-action: manipulation;
  border: unset;
  width: 100%;
  //border-bottom: .5px solid #000;
  font-size: 18px;
  line-height: 28px;
  box-sizing: border-box;
  word-wrap: break-word;
  word-wrap: break-word;
}

.formfield:focus {
  outline: unset;
}

.formsubmit {
  position: absolute;
  top: 6px;
  right: 0;
  cursor: pointer;
  padding: unset;
  background: transparent;
  border: medium none;
  border-radius: 30px;
  font-size: 16px;
  //margin-top: 47px;
  box-sizing: border-box;
}

.helf {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 18px 0;
  width: 100%;
}

.helf:first-child div {
  display: inline-block;
  text-align: left;
  max-width: 480px;
  box-sizing: border-box;
  padding: 0 16px;
}

.killings {
  border-top: solid 1px gray;
  text-align: center;
  padding: 60px 0;
}

.kill {
  vertical-align: top;
  width: 100%;
  max-width: 290px;
  padding: 16px 20px;
  display: inline-block;
}


.start {

}

.hints li {
  list-style: none;
  display: inline-block;
  padding: 0 16px;
}

.feedback {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 52px auto;
  text-align: center;
}

.feedback img {
  width: 80px;
}

.feedback p {
  font-size: 20px;
  max-width: 800px;
  display: inline-block;
}

.feedback::before {
  position: absolute;
  left: 0;
  top: 25px;
  content: '<';
  font-size: 36px;
}

.feedback::after{
  position: absolute;
  right: 0;
  top: 25px;
  content: '>';
  font-size: 36px;
}

.intro-image {
  max-height: 520px;
  overflow-y: hidden;
  overflow-x: clip;
}

.phone {
  position: relative;
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
  height: calc(360px *2.1);
  max-width: 360px;
  display: inline-block;
  background-size: 360px auto;
  background-repeat: no-repeat;
  background-image: url(/src/phone.png);
}

.phone::before {
  position: absolute;
  content: '';
  width: calc(360px + 250px);
  height: calc(1208px /1.2);
  background-size: 100% auto;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  background-image: url(/src/shadow.png);
}

.phone-screen {
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  position: relative;
  max-width: 100%;
  z-index: -1;
}

.App-logo {
  width: 150px;
  display: block;
  text-align: left;
}

.intro {
  padding: 28px 80px;
  text-align: left;
}

h2 {
  font-size: 24pt;
  line-height: 26pt;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (min-width: 800px) {
  .helf {
    width: 50%;
    max-width: 680px;
    overflow-x: clip;
  }
}

